const cm_api_key = 'cm_58797857d5504157aeebb85f9b2e182d'
const cm_secret_key = '0cbc3107c5d543bb8d04673295badda1'
let offerLoaded = false
let eventDetailsObject = null
let prefillLeadObject = null
let defaultJpub = '2afddbf9b7aa4e9a919c4b4179842314'
let selectedVehicleYear = null
let selectedVehicle2Year = null
let sendIpAlert = true
let autoPrefillId = null
let offersJpub = null
const ipApiKey = 'J8z4adFsRRbzn5N'
let trackLeadUuid = null
let gcaptchaSiteKey = '6LcT0YoqAAAAAMgFtWiwQHGqdn0C6hSmjqI-7yjm'
//let screenViewHandlers = {}

const loader = `
<div class="block">
    <div class="block-type-indicator">loader</div>
    <div class="block-content loader-block center inner-narrow">
        <div style="padding-top:20px; padding-bottom:20px;">
            <div id="loader-div" class="circle-loader circle-loader-load">
                <div class="circle-loader-checkmark draw"></div>
            </div>
        </div>
    </div>
</div>`

const onVehicleYearScreenView = (eventDetails, screenName) => {
    console.log('[onVehicleYearScreenView] eventDetails: ', eventDetails)
    //var screenName = eventDetails.screenName
    var parentDiv = 'section[name="' + screenName + '"] .multiple-choice-inner'
    var makeHtml = `
    <div class="multiple-choice-option-container">
        <div class="multiple-choice-option " hey-nextscreen="id-26c20de3" hey-important="" hey-autoredirect="true">
            <label>
                <div class="option-counter">#COUNTER#</div>
                <input onclick="window.heyflow[window.heyflowId].navigation.navigate('go','id-26c20de3');" type="radio" id="mco-0b197279" data-tracking="true" data-event-name="vehicle_year" data-type="multiple-choice" data-label="vehicleYear" name="mc-b678ad68" data-variable="vehicleYear" value="mco-0b197279" data-conditional="true" data-answer="#YEAR#" required="" data-destination="id-26c20de3" data-priority="0" data-tracking-listener="true">
                <div class="option-content picture">
                    <div class="selector-container radio-selector">
                        <span class="radio "></span>
                    </div>
                    <div class="label-container">
                        <div class="label">#YEAR#</div>
                    </div>
                </div>
                <div>
                </div>
            </label>
        </div>
    </div>`
    
    if (screenName === 'vehicle-2-year') {
        makeHtml = `
        <div class="multiple-choice-option-container">
            <div class="multiple-choice-option" hey-nextscreen="id-caeeab63" hey-important="" hey-autoredirect="true">
                <label>
                    <div class="option-counter">#COUNTER#</div>
                    <input onclick="window.heyflow[window.heyflowId].navigation.navigate('go','id-caeeab63');" type="radio" id="id-5e31e3ca" data-tracking="true" data-event-name="vehicle_year2" data-type="multiple-choice" data-label="vehicleYear2" name="id-8936b452" data-variable="vehicleYear2" value="id-5e31e3ca" data-conditional="true" data-answer="#YEAR#" required="" data-destination="id-caeeab63" data-priority="0" data-tracking-listener="true">
                    <div class="option-content picture">
                        <div class="selector-container radio-selector">
                            <span class="radio"></span>
                        </div>
                        <div class="label-container">
                            <div class="label">#YEAR#</div> 
                        </div>
                    </div>
                    <div>
                    </div>
                </label>
            </div>
        </div>`
    }

    var url = "https://feed.api.jobble.it/v1/insurance/auto/years"
    console.log('[onVehicleYearScreenView] url: ', url)
    var settings = {
      "url": url,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key
      }
    }
    
    $(parentDiv).html(loader)
    $.ajax(settings).done(function (response) {
      console.log('[onVehicleYearScreenView] year_response: ', response)
      $(parentDiv).html('')
      $.each(response, function( index, value ) {
        var innerHtml = makeHtml.replaceAll('#YEAR#', value).replaceAll('#COUNTER#', index)
        $(parentDiv).append(innerHtml)
      })
    })
}

const onVehicleMakeScreenView = (eventDetails, screenName) => {
    console.log('[onVehicleMakeScreenView] eventDetails: ', eventDetails)
    //var screenName = eventDetails.screenName
    var vehicleYear = eventDetails.fieldsSimple.vehicleYear
    var vehicleYearOther = eventDetails.fieldsSimple.vehicleYearOther
    var vehicleMakeOther = $('section[name="' + screenName + '"] select[data-variable="vehicleMakeOther"]')
    //var parentDiv = 'section[name="' + screenName + '"] .multiple-choice-inner'
    /*var makeHtml = `
    <div class="multiple-choice-option-container">
        <div class="multiple-choice-option" hey-nextscreen="id-6d719b4b" hey-important="" hey-autoredirect="true">
            <label>
                <div class="option-counter">#COUNTER#</div>
                <input onclick="window.heyflow[window.heyflowId].navigation.navigate('go','id-6d719b4b');" type="radio" id="id-0df4a2ad" data-tracking="true" data-event-name="vehicle_make" data-type="multiple-choice" data-label="vehicleMake" name="id-48e67512" data-variable="vehicleMake" value="id-0df4a2ad" data-conditional="true" data-answer="#MAKE#" required="" data-destination="id-6d719b4b" data-priority="0" data-tracking-listener="true">
                <div class="option-content picture">
                    <div class="selector-container radio-selector">
                        <span class="radio"></span>
                    </div>
                    <div class="label-container">
                        <div class="label">#MAKE#</div> 
                    </div>
                </div>
                <div>
                </div>
            </label>
        </div>
    </div>`*/
    
    if (screenName === 'vehicle-2-make') {
        vehicleYear = eventDetails.fieldsSimple.vehicleYear2
        vehicleYearOther = eventDetails.fieldsSimple.vehicleYearOther2
        vehicleMakeOther = $('section[name="' + screenName + '"] select[data-variable="vehicleMakeOther2"]')
        /*makeHtml = `
        <div class="multiple-choice-option-container">
            <div class="multiple-choice-option" hey-nextscreen="id-aa1a5671" hey-important="" hey-autoredirect="true">
                <label>
                    <div class="option-counter">#COUNTER#</div>
                    <input onclick="window.heyflow[window.heyflowId].navigation.navigate('go','id-aa1a5671');" type="radio" id="option-DnGAA1QnoZ62dU9DQ0Rt0" data-tracking="true" data-event-name="vehicle_make2" data-type="multiple-choice" data-label="vehicleMake2" name="id-66b624d3" data-variable="vehicleMake2" value="option-DnGAA1QnoZ62dU9DQ0Rt0" data-conditional="true" data-answer="#MAKE#" required="" data-destination="id-aa1a5671" data-priority="0" data-tracking-listener="true">
                    <div class="option-content picture">
                        <div class="selector-container radio-selector">
                            <span class="radio "></span>
                        </div>
                        <div class="label-container">
                            <div class="label">#MAKE#</div>
                        </div>
                    </div>
                    <div>
                    </div>
                </label>
            </div>
        </div>`*/
    }

    var url = `https://feed.api.jobble.it/v1/insurance/auto/makebyyear?year=${vehicleYearOther != null ? vehicleYearOther : vehicleYear}`
    console.log('[onVehicleMakeScreenView] url: ', url)
    var settings = {
      "url": url,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key
      }
    }
    
    //$(parentDiv).html(loader)
    $.ajax(settings).done(function (response) {
      console.log('[onVehicleMakeScreenView] make_response: ', response)
      //$(parentDiv).html('')
      vehicleMakeOther.html('')
      vehicleMakeOther.append(`<option value selected>Please select</option>`)
      $.each(response.sort(), function( index, value ) {
        //var innerHtml = makeHtml.replaceAll('#MAKE#', value).replaceAll('#COUNTER#', index)
        //$(parentDiv).append(innerHtml)
        vehicleMakeOther.append(`<option value="${value}">${value}</option>`)
      })
      if (prefillLeadObject === null) {
          return
      }
      if (screenName === 'make') {
        let makeButton = $(`input[data-variable="vehicleMake"][data-answer="${prefillLeadObject.vehicleMake}"]`)
        if (makeButton.length == 0) {
          var checkedMake = $(`section[name="make"] input[data-variable="vehicleMake"]:checked`)[0]
          if (checkedMake) {
            checkedMake.checked = false
          }
          $('select[data-variable="vehicleMakeOther"]').val(prefillLeadObject.vehicleMake)
        }
      } else if (screenName === 'vehicle-2-make') {
        let makeButton = $(`input[data-variable="vehicleMake2"][data-answer="${prefillLeadObject.vehicleMake2}"]`)
        if (makeButton.length == 0) {
          var checkedMake = $(`section[name="vehicle-2-make"] input[data-variable="vehicleMake2"]:checked`)[0]
          if (checkedMake) {
            checkedMake.checked = false
          }
          $('select[data-variable="vehicleMakeOther2"]').val(prefillLeadObject.vehicleMake2)
        }
      }
    })
}

const onVehicleModelScreenView = (eventDetails, screenName) => {
    console.log('[onVehicleModelScreenView] eventDetails: ', eventDetails)
    //var screenName = eventDetails.screenName
    var vehicleYear = eventDetails.fieldsSimple.vehicleYear
    var vehicleYearOther = eventDetails.fieldsSimple.vehicleYearOther
    var vehicleMake = eventDetails.fieldsSimple.vehicleMake
    var vehicleMakeOther = eventDetails.fieldsSimple.vehicleMakeOther
    var parentDiv = 'section[name="' + screenName + '"] .multiple-choice-inner'
    var makeHtml = `
    <div class="multiple-choice-option-container">
        <div class="multiple-choice-option " hey-nextscreen="" hey-important="" hey-autoredirect="true">
            <label>
                <div class="option-counter">1</div>
                <input onclick="window.heyflow[window.heyflowId].navigation.navigate('next','');" type="radio" id="id-e9e9799e" data-tracking="true" data-event-name="vehicle_model" data-type="multiple-choice" data-label="vehicleModel" name="id-b089c075" data-variable="vehicleModel" value="id-e9e9799e" data-answer="#MODEL#" required="" data-destination="next" data-priority="0" data-tracking-listener="true">
                <div class="option-content picture">
                    <div class="selector-container radio-selector">
                        <span class="radio"></span>
                    </div>
                    <div class="label-container">
                        <div class="label">#MODEL#</div>
                    </div>
                </div>
                <div>
                </div>
            </label>
        </div>
    </div>`
    
    if (screenName === 'vehicle-2-model') {
        vehicleYear = eventDetails.fieldsSimple.vehicleYear2
        vehicleYearOther = eventDetails.fieldsSimple.vehicleYearOther2
        vehicleMake = eventDetails.fieldsSimple.vehicleMake2
        vehicleMakeOther = eventDetails.fieldsSimple.vehicleMakeOther2
        makeHtml = `
        <div class="multiple-choice-option-container">
            <div class="multiple-choice-option" hey-nextscreen="id-0af96943" hey-important="" hey-autoredirect="true">
                <label>
                    <div class="option-counter">#COUNTER#</div>
                    <input onclick="window.heyflow[window.heyflowId].navigation.navigate('next','');" type="radio" id="option-pQFiTaJGhlrWpEfNHz9zY" data-tracking="true" data-event-name="vehicle_model2" data-type="multiple-choice" data-label="vehicleModel2" name="id-b1d97012" data-variable="vehicleModel2" value="option-pQFiTaJGhlrWpEfNHz9zY" data-conditional="true" data-answer="#MODEL#" required="" data-destination="id-0af96943" data-priority="0" data-tracking-listener="true"> 
                    <div class="option-content picture">
                        <div class="selector-container radio-selector">
                            <span class="radio"></span>
                        </div>
                        <div class="label-container">
                            <div class="label">#MODEL#</div>
                        </div>
                    </div>
                    <div>
                    </div>
                </label>
            </div>
        </div>`
    }

    var url = `https://feed.api.jobble.it/v1/insurance/auto/basemodelsbymaker?year=${vehicleYearOther != null ? vehicleYearOther : vehicleYear}&maker=${vehicleMakeOther != null ? vehicleMakeOther : vehicleMake}`
    console.log('[onVehicleModelScreenView] url: ', url)
    var settings = {
      "url": url,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key
      }
    }
    
    $(parentDiv).html(loader)
    $.ajax(settings).done(function (response) {
      console.log('[onVehicleModelScreenView] model_response: ', response)
      $(parentDiv).html('')
      $.each(response.sort(), function( index, value ) {
        var innerHtml = makeHtml.replaceAll('#MODEL#', value).replaceAll('#COUNTER#', index)
        $(parentDiv).append(innerHtml)
      })
      if (prefillLeadObject === null) {
          return
      }
      if (screenName === 'model') {
        let modelButton = $(`input[data-variable="vehicleModel"][data-answer="${prefillLeadObject.vehicleModel}"]`)
        if (modelButton.length > 0) {
          modelButton[0].checked = true
        }
      } else if (screenName === 'vehicle-2-model') {
        let modelButton = $(`input[data-variable="vehicleModel2"][data-answer="${prefillLeadObject.vehicleModel2}"]`)
        if (modelButton.length > 0) {
          modelButton[0].checked = true
        }
      }
    })
}

/*const onVehicleSubModelScreenView = (eventDetails, screenName) => {
    console.log('[onVehicleSubModelScreenView] eventDetails: ', eventDetails)
    //var screenName = eventDetails.screenName
    var vehicleYear = eventDetails.fieldsSimple.vehicleYear
    var vehicleYearOther = eventDetails.fieldsSimple.vehicleYearOther
    var vehicleMake = eventDetails.fieldsSimple.vehicleMake
    var vehicleMakeOther = eventDetails.fieldsSimple.vehicleMakeOther
    var vehicleModel = eventDetails.fieldsSimple.vehicleModel
    var parentDiv = 'section[name="' + screenName + '"] .multiple-choice-inner'
    var makeHtml = `
    <div class="multiple-choice-option-container">
        <div class="multiple-choice-option" hey-nextscreen="" hey-important="" hey-autoredirect="true">
            <label>
                <div class="option-counter">#COUNTER#</div>
                <input onclick="window.heyflow[window.heyflowId].navigation.navigate('next','');" type="radio" id="id-ce392849" data-tracking="true" data-event-name="vehicle_trim" data-type="multiple-choice" data-label="vehicleSubModel" name="id-06c1914d" data-variable="vehicleSubModel" value="id-ce392849" data-answer="#SUB_MODEL#" required="" data-destination="next" data-priority="0" data-tracking-listener="true">
                <div class="option-content picture">
                    <div class="selector-container radio-selector">
                        <span class="radio"></span>
                    </div>
                    <div class="label-container">
                        <div class="label">#SUB_MODEL#</div>
                    </div>
                </div>
                <div>
                </div>
            </label>
        </div>
    </div>`
    
    if (screenName === 'vehicle-2-trim') {
        vehicleYear = eventDetails.fieldsSimple.vehicleYear2
        vehicleYearOther = eventDetails.fieldsSimple.vehicleYearOther2
        vehicleMake = eventDetails.fieldsSimple.vehicleMake2
        vehicleMakeOther = eventDetails.fieldsSimple.vehicleMakeOther2
        vehicleModel = eventDetails.fieldsSimple.vehicleModel2
        makeHtml = `
        <div class="multiple-choice-option-container">
            <div class="multiple-choice-option" hey-nextscreen="" hey-important="" hey-autoredirect="true">
                <label>
                    <div class="option-counter">#COUNTER#</div>
                    <input onclick="window.heyflow[window.heyflowId].navigation.navigate('next','');" type="radio" id="id-d08c8c0e" data-tracking="true" data-event-name="vehicleSubModel2" data-type="multiple-choice" data-label="vehicleSubModel2" name="id-c375f52d" data-variable="vehicleSubModel2" value="id-d08c8c0e" data-answer="#SUB_MODEL#" required="" data-destination="next" data-priority="0" data-tracking-listener="true">
                    <div class="option-content picture">
                        <div class="selector-container radio-selector">
                            <span class="radio"></span>
                        </div>
                        <div class="label-container">
                            <div class="label">#SUB_MODEL#</div>        
                        </div>
                    </div>
                    <div>
                    </div>
                </label>
            </div>
        </div>`
    }

    var url = `https://feed.api.jobble.it/v1/insurance/auto/submodelbymodel?year=${vehicleYearOther != null ? vehicleYearOther : vehicleYear}&maker=${vehicleMakeOther != null ? vehicleMakeOther : vehicleMake}&model=${vehicleModel}`
    console.log('[onVehicleSubModelScreenView] url: ', url)
    var settings = {
      "url": url,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key
      }
    }
    
    $(parentDiv).html(loader)
    $.ajax(settings).done(function (response) {
      console.log('[onVehicleSubModelScreenView] sub_model_response: ', response)
      $(parentDiv).html('')
      $.each(response.sort(), function( index, value ) {
        var innerHtml = makeHtml.replaceAll('#SUB_MODEL#', value).replaceAll('#COUNTER#', index)
        $(parentDiv).append(innerHtml)
      })
    })
}*/

const loadClickOffers = (goToNextPage) => {
    console.log('[loadClickOffers] loading offers...')
    console.log(`[loadClickOffers] offerLoaded: ${offerLoaded}, goToNextPage: ${goToNextPage}`)
    if (offerLoaded) {
        return
    }
    var screenName = 'click-offers' //eventDetails.screenName
    var prefillId = autoPrefillId !== null ? autoPrefillId : sessionStorage.prefillId //'1131fb67-f938-49a7-a244-98bd66f9b965'
    var jpub = offersJpub !== null ? offersJpub : sessionStorage.jpub
    console.log(`[loadClickOffers] jpub: ${jpub}, prefillId: ${prefillId}`)
    var parentDiv = 'section[name="' + screenName + '"] .multiple-choice-inner'
    var offerHtml = `
    <div class="multiple-choice-option-container" style="max-width: 100%;">
        <div class="multiple-choice-option" hey-nextscreen="" hey-important="" hey-autoredirect="true">
            <label>
                <div class="option-counter">#COUNTER#</div>
                <input onclick="window.open('#URL#', '_blank')" type="radio" id="mco-bdd1f1b5" data-tracking="true" data-event-name="click-offers" data-type="multiple-choice" data-label="insuranceOffer" name="mc-cb0987a8" data-variable="insuranceOffer" value="mco-bdd1f1b5" data-answer="#COMPANY#" required="" data-destination="next" data-priority="0" data-tracking-listener="true">
                <div class="option-content picture option-content-flex-d" style="align-items: flex-start;">
                    <div class="selector-container radio-selector">
                        <span class="radio"></span>
                    </div>
                    <div class="image-container" style="height: auto;width: 200px;padding:10px">
                        <picture>
                            <img onerror="onImageLoadError(this)" loading="lazy" src="#LOGO#" alt="mco-bdd1f1b5" id="mco-bdd1f1b5">
                        </picture>                              
                    </div>
                    <div class="label-container" style="text-align: left;padding: 15px;">
                        <div class="label" style="font-size: 1.25rem;">#DISPLAY_NAME#</div>
                        <div class="label description">#DESCRIPTION#</div>
                    </div>
                    <div style="padding: 10px;">
					    <button class="generic-button continue large" onclick="window.open('#URL#', '_blank')">
							<div class="content align-space-between">
								<div class="label-container" style="padding:0px;">
									<span class="label" style="color: white;font-size: 1.2rem;">View My Quote</span>
								</div>
							</div>
						</button>
					</div>
                </div>
                <div>
                </div>
            </label>
        </div>
    </div>`
    
    var url = "https://feed.api.jobble.it/insurance/prefill/offer?prefillId=" + prefillId + "&jpub=" + jpub + "&max=5&qsClientId=693627"
    console.log('[loadClickOffers] url: ', url)
    var settings = {
      "url": url,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key
      }
    }
    
    $(parentDiv).html(loader)
    $.ajax(settings).done(function (response) {
      console.log('[loadClickOffers] offers_response: ', response)
      $(parentDiv).html('')
      if (response.count === null || response.count === 0) {
          $(parentDiv).append('<div style="text-align: center;">Sorry, No offer found</div>')
      } else {
          $.each(response.data, function( index, value ) {
            var innerHtml = offerHtml.replaceAll('#DESCRIPTION#', value.description)
            .replaceAll('#COUNTER#', index + 1)
            .replaceAll('#LOGO#', value.logoUrl)
            .replaceAll('#URL#',value.clickUrl)
            .replaceAll('#DISPLAY_NAME#', value.displayName)
            $(parentDiv).append(innerHtml)
          })
      }
      //$('#loader-div').removeClass('circle-loader-load')
      //$('#loader-div').addClass('circle-loader-load-success')
      offerLoaded = true
      if (goToNextPage == true) {
          setTimeout(function () {
            window.heyflow[window.heyflowId].navigation.navigate('go','screen-d4456073')
          }, 2000);
      }
    })
}

const submitLead = (eventDetails) => {
    try {
        let eventDetailsObj = eventDetails
        if (eventDetails === null || eventDetails === undefined) {
            eventDetailsObj = sessionStorage.getItem('eventDetailsObject')
        }
        const fields = eventDetailsObj.fieldsSimple
        let jpub = getQueryParam('jpub')
        if (jpub === null) {
            jpub = (fields !== undefined && 'jpub' in fields) ? fields.jpub : defaultJpub
        }
        const request = getSubmitLeadRequest(eventDetails)
        var url = "https://feed.api.jobble.it/insurance/prefill/lead"
        console.log('[submitLead] url: ', url)
        console.log('[submitLead] request: ', request)
        
        var settings = {
          "url": url,
          "method": "PUT",
          "timeout": 0,
          "headers": {
            "cm_api_key": cm_api_key,
            "cm_secret_key": cm_secret_key,
            "Content-Type": "application/json"
          },
          "data": JSON.stringify(request)
        }
        
        $.ajax(settings).done(function (response) {
          console.log('[submitLead] response: ', response)
          offersJpub = jpub
          autoPrefillId = response.autoPrefillId
          sessionStorage.setItem('jpub', jpub)
          sessionStorage.setItem('prefillId', response.autoPrefillId)
          trackLead('offers')
          loadClickOffers(true)
        })
    } catch(error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
    
}

const getSubmitLeadRequest = (eventDetails) => {
    let eventDetailsObj = eventDetails
    if (eventDetails === null || eventDetails === undefined) {
        eventDetailsObj = sessionStorage.getItem('eventDetailsObject')
    }
    const fields = eventDetailsObj.fieldsSimple
    console.log('[submitLead] fields: ', fields)
    let jpub = getQueryParam('jpub')
    if (jpub === null) {
        jpub = (fields !== undefined && 'jpub' in fields) ? fields.jpub : defaultJpub
    }
    let submissionURL = window.location.origin + window.location.pathname
    //let submissionURL = 'https://benefits.jobble.com/auto-2c79eb'
    //let consumerSourceIP = fields.consumerSourceIP
    let consumerSourceIP = sessionStorage.getItem('consumerSourceIP')
    sendIpSlackAlert(null)
    if (consumerSourceIP === null || consumerSourceIP === undefined) {
        getClientIP(false)
        consumerSourceIP = sessionStorage.getItem('consumerSourceIP')
    }
    /*if (window.ipAddress === null || window.ipAddress === undefined || window.ipAddress === '') {
        window.ipAddress = '172.58.255.184'
    }*/
    let utmMedium = getQueryParam('utm_medium')
    if (utmMedium === null) {
        utmMedium = (fields !== undefined && 'utm_medium' in fields) ? fields.utm_medium : ''
    }
     let buyerslist = []
    $('input[data-label="carrier_match"]:checked').each(function() {
       console.log($(this).data(''));
        buyerslist.push($(this).data('answer'))
    });
   
    const request = {
        "autoPrefillId": fields.prefillId,
        "isLeadSell": true,
        "heyflowId": window.heyflowId,
        "isHeyFlowLead": true,
        "jpub": jpub.trim(),
        "step": eventDetails.screenName,
        "isWebFlow": true,
        "auctionId": generateUUID(),
        "campaignId": generateUUID(),
        "bidPriceCents": 0,
        "submissionURL": submissionURL,
        "consumerSourceIP": consumerSourceIP,
        "leadIdentifier": generateUUID(),
        "userAgent": navigator.userAgent,
        "requestUri": window.location.href,
        //"leadType": null,
        "externalId": fields.external_id,
        "email": fields.email,
        "postalCode": fields.postalCode,
        "city": fields.city,
        "state": fields.state,
        "county": fields.country,
        "address": fields.addr1,
        "universalLeadId": fields.leadid_token,
        "trustedFormCertUrl": fields.xxTrustedFormToken,
        "consentLanguage": fields.consentLanguage,
        "firstName": fields.firstName,
        "lastName": fields.lastName,
        "mobilePhone": fields.homePhone.replaceAll(' ', '').replaceAll('+1',''),
        "maritalStatus": fields.maritalStatus,
        "gender": fields.gender,
        "birthDate": fields.birthDate,
        "militaryStatus": fields.militaryStatus,
        //"vin": null,
        "vehicleYear": fields.vehicleYearOther != null ? fields.vehicleYearOther : fields.vehicleYear,
        "vehicleMake": fields.vehicleMakeOther != null ? fields.vehicleMakeOther : fields.vehicleMake,
        "vehicleModel": fields.vehicleModel,
        "vehicleSubModel": fields.vehicleSubModel,
        "vehiclePrimaryUse": fields.vehiclePrimaryUse,
        "ownedLeasedOrFinanced": fields.ownedLeasedOrFinanced,
        "homeOwner": fields.residenceStatus === 'Owner',
        "currentlyInsured": fields.currentlyInsured === 'Yes',
        "insuranceCarrier": fields.insuranceCarrier,
        "education": fields.education,
        "doesRequiresSr22": fields.doesRequireSR22 === 'Yes',
        "creditRating": fields.creditRating,
        //"annualMileage": null,
        //"dailyMileage": null,
        "isAutoWarrantyOpted": fields.autowarranty === 'Yes',
        "utmSource": fields.utm_source,
        "utmMedium": utmMedium,
        "utmCampaign": fields.utm_campaign,
        "utmContent": fields.utm_content,
        "utmTerm": fields.utm_term,
        "licenseStatus": fields.licenseStatus,
        "crossSellHome": fields.crossSellHome,
        "occupation": fields.occupation,
        "continuousCoverage": fields.continuousCoverage,
        "tcpaAgreement": fields.tcpa_agreement == '1',
        "tcpaAgreementBrokered": true,
        "consentLanguageBrokered": fields.consentLanguage2,
        
        "vehicleYear2": fields.vehicleYearOther2 != null ? fields.vehicleYearOther2 : fields.vehicleYear2,
        "vehicleMake2": fields.vehicleMakeOther2 != null ? fields.vehicleMakeOther2 : fields.vehicleMake2,
        "vehicleModel2": fields.vehicleModel2,
        "vehicleSubModel2": fields.vehicleSubModel2,
        "vehiclePrimaryUse2": fields.vehiclePrimaryUse2,
        "ownedLeasedOrFinanced2": fields.ownedLeasedOrFinanced2,
        "hasConsentPreChecked": 'No',
        "buyers": buyerslist
    }
    return request
}

const getQueryParam = (paramName) => {
    const urlParams = new URLSearchParams(window.location.search)
    console.log(`[getQueryParam] has ${paramName}: ${urlParams.has(paramName)}`)
    let paramValue = urlParams.has(paramName) ? urlParams.get(paramName) : null
    console.log(`[getQueryParam] ${paramName}: ${paramValue}`)
    return paramValue
}

const trackLead = (screenName) => {
    
    console.log(`[trackLead] screenName: ${screenName}`)
    console.log(`[trackLead] trackLeadUuid: ${trackLeadUuid}`)
    
    let consumerSourceIP = sessionStorage.getItem('consumerSourceIP')
    if (consumerSourceIP === null || consumerSourceIP === undefined) {
        getClientIP(false)
        consumerSourceIP = sessionStorage.getItem('consumerSourceIP')
    }
    
    const urlParams = new URLSearchParams(window.location.search)
    console.log(`[trackLead] has jpub: ${urlParams.has('jpub')}`)
    let jpub = urlParams.has('jpub') ? urlParams.get('jpub') : defaultJpub
    console.log(`[trackLead] jpub: ${jpub}`)
    
    const request = {
        "uuid": trackLeadUuid,
        "steps": screenName,
        "publisher": jpub,
        "ip": consumerSourceIP,
        "requestUri": window.location.href,
        "userBrowser": getClientBrowser(),
        "userOs": getClientOS(),
        "flowId" : window.heyflowId,
        "isHeyFlow" : true
    }
    
    var url = 'https://feed.api.jobble.it/v1/lead/track'
    console.log('[trackLead] url: ', url)
    console.log('[trackLead] request: ', request)
    
    var settings = {
      "url": url,
      "method": "PUT",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key,
        "Content-Type": "application/json"
      },
      "data": JSON.stringify(request)
    }
    
    $.ajax(settings).done(function (response) {
        console.log('[trackLead] response: ', response)
    })
}

const getClientOS = () => {
  let userAgent = window.navigator.userAgent
  let platform = window.navigator.platform
  let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  let iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }
  return os
}

const getClientBrowser = () => {
    var userAgent = navigator.userAgent
    if (userAgent.indexOf("Edg") > -1) {
        return "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome"
    } else if (userAgent.indexOf("Firefox") > -1) {
        return "Firefox"
    } else if (userAgent.indexOf("Safari") > -1) {
        return "Safari"
    } else if (userAgent.indexOf("Opera") > -1) {
        return "Opera"
    } else if (userAgent.indexOf("Trident") > -1 || userAgent.indexOf("MSIE") > -1) {
        return "Internet Explorer"
    }
    return userAgent //"Unknown";
}

const setAddressFields = (autocomplete) => {
    
  const place = autocomplete.getPlace()
  console.log('place: ', place)
  let address1 = ""
  let postcode = ""
  
  var street = $('section[name="address"] input[data-variable="addr1"]')[0]
  var city = $('section[name="address"] input[data-variable="city"]')[0]
  var state = $('section[name="address"] select[data-variable="state"]')[0]
  var postalCode = $('section[name="address"] input[data-variable="postalCode"]')[0]
  
  for (const component of place.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name} ${address1}`;
        console.log('street_number:', `${component.long_name} ${address1}`)
        break;
      }
      case "route": {
        address1 += component.long_name;
        console.log('route:', component.long_name)
        break;
      }
      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        console.log('postal_code:', postcode)
        break;
      }
    //   case "postal_code_suffix": {
    //     postcode = `${postcode}-${component.long_name}`;
    //     console.log('postal_code_suffix:', postcode)
    //     break;
    //   }
      case "locality":
        city.value = component.long_name;
        console.log('city:', component.short_name)
        break;
      case "administrative_area_level_1": {
        state.value = component.short_name
        console.log('state:', component.short_name)
        break;
      }
      case "country":
        //document.querySelector("#country").value = component.long_name;
        break;
    }
  }
  street.value = address1;
  postalCode.value = postcode;
}

const initVehicleYearSelect = (screenName, vehicleYearSelect) => {
    console.log(`[initVehicleYearSelect] initializing vehicle year select on screen ${screenName}`)
    vehicleYearSelect.change(function(){
        console.log(`[initVehicleYearSelect] value:${this.value}, screenName: ${screenName}`)
        if (this.value !== '') {
            var checkedYear = $(`section[name="${screenName}"] input[data-variable^="vehicleYear"]:checked`)[0]
            if (checkedYear) {
                checkedYear.checked = false
            }
            if (screenName === 'year') {
                window.heyflow[window.heyflowId].navigation.navigate('go','id-26c20de3')
            } else if (screenName === 'vehicle-2-year') {
                window.heyflow[window.heyflowId].navigation.navigate('go','id-caeeab63')
            }
        }
    })
}

const initVehicleMakeSelect = (screenName, vehicleMakeSelect) => {
    console.log(`[initVehicleMakeSelect] initializing vehicle make select, screenName: ${screenName}`)
    vehicleMakeSelect.change(function(){
        console.log('[initVehicleMakeSelect] value:' + this.value)
        if (this.value !== '') {
            var checkedMake = $(`section[name="${screenName}"] input[data-variable^="vehicleMake"]:checked`)[0]
            if (checkedMake) {
                checkedMake.checked = false
            }
            if (screenName === 'make') {
                window.heyflow[window.heyflowId].navigation.navigate('go','id-6d719b4b')
            } else if (screenName === 'vehicle-2-make') {
                window.heyflow[window.heyflowId].navigation.navigate('go','id-aa1a5671')
            }
        }
    })
}

const initVehicleYearButtonsClick = (screenName) => {
    console.log(`[initVehicleYearsClick] initializing vehicle year button click event on screen ${screenName}`)
    var yearButtons = $(`section[name="${screenName}"] .multiple-choice-inner input[data-variable^="vehicleYear"]`)
    var vehicleYearSelect = $(`section[name="${screenName}"] select[data-variable^="vehicleYearOther"]`)
    yearButtons.click(function(){
        console.log('[initVehicleYearsClick] data-answer:' + $(this).data('answer'))
        vehicleYearSelect.val('')
        if (screenName === 'year') {
            selectedVehicleYear = $(this).data('answer')
            onVehicleMakeScreenView({
                fieldsSimple: {
                    vehicleYear: selectedVehicleYear
                }
            }, 'make')
        } else {
            selectedVehicle2Year = $(this).data('answer')
            onVehicleMakeScreenView({
                fieldsSimple: {
                    vehicleYear2: selectedVehicle2Year
                }
            }, 'vehicle-2-make')
        }
    })
}

const initVehicleMakeButtonsClick = (screenName) => {
    console.log(`[initVehicleMakeButtonsClick] initializing vehicle make button click event on screen ${screenName}`)
    var makeButtons = $(`section[name="${screenName}"] .multiple-choice-inner input[data-variable^="vehicleMake"]`)
    var vehicleMakeSelect = $(`section[name="${screenName}"] select[data-variable^="vehicleMakeOther"]`)
    makeButtons.click(function(){
        console.log('[initVehicleMakeButtonsClick] data-answer:' + $(this).data('answer'))
        vehicleMakeSelect.val('')
        if (screenName === 'make') {
            onVehicleModelScreenView({
                fieldsSimple: {
                    vehicleYear: selectedVehicleYear,
                    vehicleMake: $(this).data('answer')
                }
            }, 'model')
        } else {
            onVehicleModelScreenView({
                fieldsSimple: {
                    vehicleYear2: selectedVehicle2Year,
                    vehicleMake2: $(this).data('answer')
                }
            }, 'vehicle-2-model')
        }
    })
}

const initVehicle2ButtonsClick = () => {
    console.log(`[initVehicle2ButtonsClick] initializing vehicle2 buttons click event`)
    var optionNo = $(`section[name="vehicle-2"] .multiple-choice-inner input[data-variable="vehicleId2"][data-answer="0"]`)
    optionNo.click(function(){
        console.log('[initVehicle2ButtonsClick] data-answer:' + $(this).data('answer'))
        
        let vehicleYear2 = $(`input[data-variable="vehicleYear2"]:checked`)
        let vehicleYearOther2 = $('select[data-variable="vehicleYearOther2"]')
        if (vehicleYear2.length > 0) {
            vehicleYear2[0].checked = false
        } 
        if (vehicleYearOther2.length > 0) {
            vehicleYearOther2.val('')
        }
        
        let vehicleMake2 = $(`input[data-variable="vehicleMake2"]:checked`)
        let vehicleMakeOther2 = $('select[data-variable="vehicleMakeOther2"]')
        if (vehicleMake2.length > 0) {
            vehicleMake2[0].checked = false
        }
        if (vehicleMakeOther2.length > 0) {
            vehicleMakeOther2.val('')
        }
        
        let vehicleModel2 = $(`input[data-variable="vehicleModel2"]:checked`)
        if (vehicleModel2.length > 0) {
            vehicleModel2[0].checked = false
        }
        
        let vehiclePrimaryUse2 = $(`input[data-variable="vehiclePrimaryUse2"]:checked`)
        if (vehiclePrimaryUse2.length > 0) {
            vehiclePrimaryUse2[0].checked = false
        }
        
        let ownedLeasedOrFinanced2 = $(`input[data-variable="ownedLeasedOrFinanced2"]:checked`)
        if (ownedLeasedOrFinanced2.length > 0) {
            ownedLeasedOrFinanced2[0].checked = false
        }
    })
}

const initContinueButtons = (screenName) => {
    let yearContinueButton = $(`section[name="${screenName}"] button.continue`)
    if (yearContinueButton.length > 0) {
        let clickEvent = yearContinueButton.attr('onclick')
        yearContinueButton.removeAttr('onclick')
        yearContinueButton.click(function(e) {
            console.log(`continue from screenName: ${screenName}...`)
            let valid = true
            let alertMessage = ''
            if (screenName === 'year') {
                let vehicleYear = $(`input[data-variable="vehicleYear"]:checked`)
                let vehicleYearOther = $('select[data-variable="vehicleYearOther"]')
                if (vehicleYear.length === 0 && vehicleYearOther.val() === null) {
                    valid = false
                    alertMessage = 'Please select your vehicle year'
                } else {
                    if (vehicleYear.length > 0) {
                        selectedVehicleYear = vehicleYear.data('answer')
                    } else if (vehicleYearOther.val() !== null) {
                        selectedVehicleYear = vehicleYearOther.val()
                    }
                    console.log(`[initContinueButtons] selectedVehicleYear: ${selectedVehicleYear}...`)
                    onVehicleMakeScreenView({
                        fieldsSimple: {
                            vehicleYear: selectedVehicleYear
                        }
                    }, 'make')
                }
            } else if (screenName === 'make') {
                let vehicleMake = $(`input[data-variable="vehicleMake"]:checked`)
                let vehicleMakeOther = $('select[data-variable="vehicleMakeOther"]')
                if (vehicleMake.length === 0 && vehicleMakeOther.val() === '') {
                    valid = false
                    alertMessage = 'Please select your vehicle make'
                } else {
                    let selectedVehicleMake = null
                    if (vehicleMake.length > 0) {
                        selectedVehicleMake = vehicleMake.data('answer')
                    } else if (vehicleMakeOther.val() !== null) {
                        selectedVehicleMake = vehicleMakeOther.val()
                    }
                    onVehicleModelScreenView({
                        fieldsSimple: {
                            vehicleYear: selectedVehicleYear,
                            vehicleMake: selectedVehicleMake
                        }
                    }, 'model')
                }
            } else if (screenName === 'vehicle-2-year') {
                let vehicleYear2 = $(`input[data-variable="vehicleYear2"]:checked`)
                let vehicleYearOther2 = $('select[data-variable="vehicleYearOther2"]')
                if (vehicleYear2.length === 0 && vehicleYearOther2.val() === null) {
                    valid = false
                    alertMessage = 'Please select your vehicle year'
                } else {
                    if (vehicleYear2.length > 0) {
                        selectedVehicle2Year = vehicleYear2.data('answer')
                    } else if (vehicleYearOther2.val() !== null) {
                        selectedVehicle2Year = vehicleYearOther2.val()
                    }
                    console.log(`[initContinueButtons] selectedVehicle2Year: ${selectedVehicle2Year}...`)
                    onVehicleMakeScreenView({
                        fieldsSimple: {
                            vehicleYear2: selectedVehicle2Year
                        }
                    }, 'vehicle-2-make')
                }
            } else if (screenName === 'vehicle-2-make') {
                let vehicleMake2 = $(`input[data-variable="vehicleMake2"]:checked`)
                let vehicleMakeOther2 = $('select[data-variable="vehicleMakeOther2"]')
                if (vehicleMake2.length === 0 && vehicleMakeOther2.val() === '') {
                    valid = false
                    alertMessage = 'Please select your vehicle make'
                } else {
                    let selectedVehicleMake2 = null
                    if (vehicleMake2.length > 0) {
                        selectedVehicleMake2 = vehicleMake2.data('answer')
                    } else if (vehicleMakeOther2.val() !== null) {
                        selectedVehicleMake2 = vehicleMakeOther2.val()
                    }
                    onVehicleModelScreenView({
                        fieldsSimple: {
                            vehicleYear2: selectedVehicle2Year,
                            vehicleMake2: selectedVehicleMake2
                        }
                    }, 'vehicle-2-model')
                }
            }
            console.log(`[initContinueButtons] valid: ${valid}`)
            if (!valid) {
                alert(alertMessage)
                return
            }
            eval(clickEvent)
        })
    }
}

const generateUUID = () => {
    let date = new Date().getTime();
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (date + Math.random() * 16) % 16 | 0;
            date = Math.floor(date / 16);
            return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
}

const getPrefillLead = () => {
    const urlParams = new URLSearchParams(window.location.search)
    console.log(`[getPrefillLead] has prefillId: ${urlParams.has('prefillId')}`)
    if (!urlParams.has('prefillId')) {
        return
    }
    const prefillId = urlParams.get('prefillId')
    console.log('[getPrefillLead] prefillId: ', prefillId)
    var url = `https://feed.api.jobble.it/insurance/prefill/lead?prefillId=${prefillId}`
    console.log('[getPrefillLead] url: ', url)
    var settings = {
      "url": url,
      "method": "GET",
      "timeout": 0,
      "headers": {
        "cm_api_key": cm_api_key,
        "cm_secret_key": cm_secret_key,
        "Content-Type": "application/json"
      }
    }
    
    $.ajax(settings).done(function (response) {
      console.log('[getPrefillLead] response: ', response)
      prefillLeadObject = response
      populateFields()
    })
}

const populateFields = () => {
    
    if (prefillLeadObject.postalCode) {
        let input = $(`input[data-variable="garagingZip"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.postalCode
        }
    }
    
    if (prefillLeadObject.vehicleYear) {
        let button = $(`input[data-variable="vehicleYear"][data-answer="${prefillLeadObject.vehicleYear}"]`)
        if (button.length > 0) {
            button[0].checked = true
        } else {
            $('select[data-variable="vehicleYearOther"]').val(prefillLeadObject.vehicleYear)
        }
    }
    if (prefillLeadObject.vehicleMake) {
        let button = $(`input[data-variable="vehicleMake"][data-answer="${prefillLeadObject.vehicleMake}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.vehiclePrimaryUse) {
        let button = $(`input[data-variable="vehiclePrimaryUse"][data-answer="${prefillLeadObject.vehiclePrimaryUse}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.ownedLeasedOrFinanced) {
        let button = $(`input[data-variable="ownedLeasedOrFinanced"][data-answer="${prefillLeadObject.ownedLeasedOrFinanced}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    
    if (prefillLeadObject.vehicleYear2) {
        let button = $(`input[data-variable="vehicleYear2"][data-answer="${prefillLeadObject.vehicleYear2}"]`)
        if (button.length > 0) {
            button[0].checked = true
        } else {
            $('select[data-variable="vehicleYearOther2"]').val(prefillLeadObject.vehicleYear2)
        }
    }
    if (prefillLeadObject.vehicleMake2) {
        let button = $(`input[data-variable="vehicleMake2"][data-answer="${prefillLeadObject.vehicleMake2}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.vehiclePrimaryUse2) {
        let button = $(`input[data-variable="vehiclePrimaryUse2"][data-answer="${prefillLeadObject.vehiclePrimaryUse2}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.ownedLeasedOrFinanced2) {
        let button = $(`input[data-variable="ownedLeasedOrFinanced2"][data-answer="${prefillLeadObject.ownedLeasedOrFinanced2}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    
    if (prefillLeadObject.currentlyInsured) {
        let button = $(`input[data-variable="currentlyInsured"][data-answer="Yes" i]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    } else if (!prefillLeadObject.currentlyInsured) {
        let button = $(`input[data-variable="currentlyInsured"][data-answer="No" i]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.insuranceCarrier) {
        let button = $(`input[data-variable="insuranceCarrier"][data-answer="${prefillLeadObject.insuranceCarrier}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.continuousCoverage) {
        let button = $(`input[data-variable="continuousCoverage"][data-answer="${prefillLeadObject.continuousCoverage}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.gender) {
        let button = $(`input[data-variable="gender"][data-answer="${prefillLeadObject.gender}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.maritalStatus) {
        let button = $(`input[data-variable="maritalStatus"][data-answer="${prefillLeadObject.maritalStatus}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.education) {
        let button = $(`input[data-variable="education"][data-answer="${prefillLeadObject.education}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.militaryStatus) {
        let button = $(`input[data-variable="militaryStatus"][data-answer="yes" i]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    } else if (!prefillLeadObject.militaryStatus) {
        let button = $(`input[data-variable="militaryStatus"][data-answer="no" i]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.creditRating) {
        let button = $(`input[data-variable="creditRating"][data-answer="${prefillLeadObject.creditRating}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.occupation) {
        let button = $(`input[data-variable="occupation"][data-answer="${prefillLeadObject.occupation}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.residenceStatus) {
        let button = $(`input[data-variable="residenceStatus"][data-answer="${prefillLeadObject.residenceStatus}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.crossSellHome) {
        let button = $(`input[data-variable="crossSellHome"][data-answer="${prefillLeadObject.crossSellHome}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.autowarranty) {
        let button = $(`input[data-variable="autowarranty"][data-answer="${prefillLeadObject.autowarranty}"]`)
        if (button.length > 0) {
            button[0].checked = true
        }
    }
    if (prefillLeadObject.firstName) {
        let input = $(`input[data-variable="firstName"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.firstName
        }
    }
    if (prefillLeadObject.lastName) {
        let input = $(`input[data-variable="lastName"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.lastName
        }
    }
    if (prefillLeadObject.birthDate) {
        let input = $(`input[data-variable="birthDate"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.birthDate
        }
    }
    if (prefillLeadObject.address) {
        let input = $(`input[data-variable="addr1"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.address
        }
    }
    if (prefillLeadObject.city) {
        let input = $(`input[data-variable="city"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.city
        }
    }
    if (prefillLeadObject.state) {
        let input = $(`select[data-variable="state"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.state
        }
    }
    if (prefillLeadObject.postalCode) {
        let input = $(`input[data-variable="postalCode"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.postalCode
        }
    }
    if (prefillLeadObject.email) {
        let input = $(`input[data-variable="email"]`)
        if (input.length > 0) {
            input[0].value = prefillLeadObject.email
        }
    }
    if (prefillLeadObject.mobilePhone) {
        setTimeout(function() {
            let input = $(`input[data-variable="homePhone"]`)
            console.log('input[data-variable="homePhone"] length: ' + input.length)
            if (input.length > 0) {
                input[0].value = '+1 ' + prefillLeadObject.mobilePhone.replaceAll('-', ' ')
            }
        }, 5000)
    }
    if (prefillLeadObject.doesRequiresSr22) {
        let input = $(`input[data-variable="doesRequireSR22"]`)
        if (input.length > 0) {
            input[0].value = 'Yes'
        }
    } else if (!prefillLeadObject.doesRequiresSr22) {
        let input = $(`input[data-variable="doesRequireSR22"]`)
        if (input.length > 0) {
            input[0].value = 'No'
        }
    }
}

window.addEventListener('heyflow-init', (event) => {
    console.log('[heyflow-init] event.detail: ', event.detail)
    
    window.heyflowId = event.detail.flowID
    trackLeadUuid = generateUUID()
    console.log('[heyflow-init] window.heyflowId: ', window.heyflowId)
    console.log('[heyflow-init] trackLeadUuid: ', trackLeadUuid)
    
    getClientIP(true)
    getPrefillLead()
    
    /*screenViewHandlers = {
        'make': onVehicleMakeScreenView,
        'model': onVehicleModelScreenView,
        'vehicle-2-make': onVehicleMakeScreenView,
        'vehicle-2-model': onVehicleModelScreenView,
        'trim': onVehicleSubModelScreenView,
        'vehicle-2-trim': onVehicleSubModelScreenView
    }*/
    
    var address1 = $('section[name="address"] input[data-variable="addr1"]')[0]
    initAutocomplete(address1, setAddressFields)
    
    var vehicleYearSelect1 = $('section[name="year"] select[data-variable="vehicleYearOther"]')
    initVehicleYearSelect('year', vehicleYearSelect1)
    
    var vehicleYearSelect2 = $('section[name="vehicle-2-year"] select[data-variable="vehicleYearOther2"]')
    initVehicleYearSelect('vehicle-2-year', vehicleYearSelect2)
    
    initVehicleYearButtonsClick('year')
    initVehicleYearButtonsClick('vehicle-2-year')
    
    initVehicleMakeButtonsClick('make')
    initVehicleMakeButtonsClick('vehicle-2-make')
    
    var vehicleMakeSelect1 = $('section[name="make"] select[data-variable="vehicleMakeOther"]')
    initVehicleMakeSelect('make', vehicleMakeSelect1)
    
    var vehicleMakeSelect2 = $('section[name="vehicle-2-make"] select[data-variable="vehicleMakeOther2"]')
    initVehicleMakeSelect('vehicle-2-make', vehicleMakeSelect2)
    
    initVehicle2ButtonsClick()
    
    initContinueButtons('year')
    initContinueButtons('make')
    initContinueButtons('vehicle-2-year')
    initContinueButtons('vehicle-2-make')
    // initOptInsuranceInputs()
    initOptInsuranceInputsOnChange()
})

window.addEventListener('heyflow-screen-view', (event) => {
    console.log('[heyflow-screen-view] event.detail: ', event.detail)
    let screenName = event.detail.screenName
    
    let consumerSourceIP = sessionStorage.getItem('consumerSourceIP')
    if (consumerSourceIP === null || consumerSourceIP === undefined) {
        getClientIP(false)
        let consumerSourceIPInput = $('section[name="start"] input[data-variable="consumerSourceIP"]')
        if (consumerSourceIPInput.length > 0) {
            consumerSourceIPInput[0].value = sessionStorage.getItem('consumerSourceIP')
        }
    }
    
    if (screenName === 'start') {
        trackLead(screenName)
    }
    
    
    // if(screenName === 'opt-ins') {
    //     initLeadId()
    // }
          
    if (screenName === 'loader') {
        console.log('[heyflow-screen-view] eventDetailsObject: ', eventDetailsObject)
        //submitLead(eventDetailsObject)
        verifyGCaptchaToken()
    } else if (screenName === 'click-offers' && !offerLoaded) {
        loadClickOffers(false)
    } else if (screenName === 'year') {
        //onVehicleYearScreenView(event.detail)
        //var vehicleYearSelect1 = $('section[name="year"] select[data-variable="vehicleYearOther"]')
        //vehicleYearSelect1.val('')
    } else if (screenName === 'vehicle-2-year') {
        //onVehicleYearScreenView(event.detail)
        //var vehicleYearSelect2 = $('section[name="vehicle-2-year"] select[data-variable="vehicleYearOther2"]')
        //vehicleYearSelect2.val('')
    } else if (screenName === 'make') {
        //var vehicleMakeSelect1 = $('section[name="make"] select[data-variable="vehicleMakeOther"]')
        //vehicleMakeSelect1.val('')
    } else if (screenName === 'vehicle-2-make') {
        //var vehicleMakeSelect2 = $('section[name="vehicle-2-make"] select[data-variable="vehicleMakeOther2"]')
        //vehicleMakeSelect2.val('')
    }
})


window.addEventListener('heyflow-screen-leave', (event) => {
    console.log('[heyflow-screen-leave] event.detail: ', event.detail)
    sendSlackAlert()
})


window.addEventListener('heyflow-change', (event) => {
    try {
        console.log('[heyflow-change] event.detail: ', event.detail)
        console.log('[heyflow-change] event.detail.fieldsSimple:', event.detail.fieldsSimple)
        
        eventDetailsObject = event.detail;
        console.log('[heyflow-change] eventDetailsObjectl: ', eventDetailsObject)
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('ownedLeasedOrFinanced');
        event.detail.fieldsSimple.ownedLeasedOrFinanced = myParam
        //var screenHandler = screenViewHandlers[event.detail.screenName]
        //if (screenHandler) {
        //    screenHandler(event.detail)
        //}
        switch(event.detail.screenName) {
            case 'year':
                onVehicleMakeScreenView(event.detail, 'make')
                break
            case 'make':
                onVehicleModelScreenView(event.detail, 'model')
                break
            /*case 'model':
                onVehicleSubModelScreenView(event.detail, 'trim')
                break*/
            case 'vehicle-2-year':
                onVehicleMakeScreenView(event.detail, 'vehicle-2-make')
                break
            case 'vehicle-2-make':
                onVehicleModelScreenView(event.detail, 'vehicle-2-model')
                break
            /*case 'vehicle-2-model':
                onVehicleSubModelScreenView(event.detail, 'vehicle-2-trim')
                break*/
        }
    } catch (error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
})

window.addEventListener('heyflow-submit', (event) => {
    try {
        console.log('[heyflow-submit] event.detail: ', event.detail)
        eventDetailsObject = event.detail
        sessionStorage.setItem('eventDetailsObject', event.detail)
        //submitLead(event.detail)
    } catch (error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
})

const getClientIP = (isAsync) => {
    try {
        var url = `https://pro.ip-api.com/json?key=${ipApiKey}`
        console.log('[getClientIP] url: ', url)
        var settings = {
          "url": url,
          "method": "GET",
          "timeout": 0,
          "async": isAsync === null || isAsync === undefined ? true : isAsync
        }
        $.ajax(settings).done(function (response) {
          console.log('[getClientIP] response: ', response)
          sessionStorage.setItem('consumerSourceIP', response.query)
          //window.ipAddress = response.query
          let consumerSourceIP = $('section[name="start"] input[data-variable="consumerSourceIP"]')
          if (consumerSourceIP.length > 0) {
            console.log('[getClientIP] response.query: ', response.query)
            consumerSourceIP[0].value = response.query
          }
          if (sendIpAlert) {
            sendIpSlackAlert(response)
          }
          console.log('[getClientIP] sessionStorage.consumerSourceIP: ', sessionStorage.getItem('consumerSourceIP'))
        })
    } catch (error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
    //return window.ipAddress
}

const verifyGCaptchaToken = () => {
    let jpub = getQueryParam('jpub')
    if (jpub === null) {
        jpub = defaultJpub
    }
    var parentDiv = `section[name="click-offers"] .multiple-choice-inner`
    grecaptcha.ready(function() {
        grecaptcha.execute(gcaptchaSiteKey, {action: 'submit'}).then(function(token) {
            //alert(token)
            let payload = null
            if (eventDetailsObject !== null) {
                let request = getSubmitLeadRequest(eventDetailsObject)
                payload = JSON.stringify(request)
            }
            const request = {
              "jpub": jpub,
              "token": token,
              "payload": payload
            };
            let url = "https://feed.api.jobble.it/validator/google/recaptcha/siteverify"
            console.log('[verifyGCaptchaToken] url: ', url)
            console.log('[verifyGCaptchaToken] request: ', request)
            let settings = {
              "url": url,
              "method": "POST",
              "timeout": 0,
              "headers": {
                "cm_api_key": cm_api_key,
                "cm_secret_key": cm_secret_key,
                "Content-Type": "application/json"
              },
              "data": JSON.stringify(request)
            }
            
            $.ajax(settings).done(function (response) {
              console.log('[verifyGCaptchaToken] response: ', response)
              $(parentDiv).html('')
            submitLead(eventDetailsObject)
            //   if (response.success) {
            //     console.log('[verifyGCaptchaToken] eventDetailsObject: ', eventDetailsObject)
            //     submitLead(eventDetailsObject)
            //   } else {
            //       $(parentDiv).append('<div style="text-align: center;">Your request has been blocked</div>')
            //       offerLoaded = true
            //       sendCaptchFailedAlert(response)
            //       setTimeout(function () {
            //         window.heyflow[window.heyflowId].navigation.navigate('go','screen-d4456073')
            //       }, 2000);
            //   }
            })
        });
    });
}

// const initOptInsuranceInputs = () => {
//     console.log('[initOptInsuranceInputs] initializing opt-in insurance checkboxes')
//     $('input[data-label="leadid_tcpa_brandnames_selectalllbl"]').click(function() { 
//         console.log(`[initOptInsuranceInputs] leadid_tcpa_brandnames_selectalllbl: ${this.checked}`)
//         $('input[data-label="carrier_match"]').prop('checked', this.checked)
//         //updateConsentLanguage()
//     })
    // $('input[data-label="carrier_match"]').click(function() {
    //     let totalOptions = $('input[data-label="carrier_match"]').length
    //     let checkedOptions = $('input[data-label="carrier_match"]:checked').length
    //     $('input[data-label="leadid_tcpa_brandnames_selectalllbl"]').prop('checked', false)
    //     if (checkedOptions === totalOptions) {
    //         $('input[data-label="leadid_tcpa_brandnames_selectalllbl"]').prop('checked', true)
    //     }
    //     //updateConsentLanguage()
    // })
// }

const initOptInsuranceInputsOnChange = () => {
    console.log('[initOptInsuranceInputsOnChange] initializing opt-in insurance checkboxes')
    const selectAllCheckbox = document.getElementById("leadid_tcpa_brandnames_selectall");
    const checkboxes = document.querySelectorAll('input[type="checkbox"][name="leadid_tcpa_brandname"]');
    selectAllCheckbox.addEventListener('change', function() {
      if (selectAllCheckbox.checked) {
            // Select all checkboxes
            checkboxes.forEach(checkbox => {
                if (!checkbox.checked) {
                    $('#' + checkbox.id).trigger('click'); // Trigger click only if not already checked
                }
            });
        } else {
            // Deselect all checkboxes
            checkboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    $('#' + checkbox.id).trigger('click'); // Trigger click only if currently checked
                }
            });
        }
    });
    $('input[data-label="carrier_match"]').click(function() {
        let totalOptions = $('input[data-label="carrier_match"]').length
        let checkedOptions = $('input[data-label="carrier_match"]:checked').length
        $('input[data-label="leadid_tcpa_brandnames_selectalllbl"]').prop('checked', false)
        if (checkedOptions === totalOptions) {
            $('input[data-label="leadid_tcpa_brandnames_selectalllbl"]').prop('checked', true)
        }
    })
}

const updateConsentLanguage = () => {
    let companies = []
    $('input[data-label="carrier_match"]:checked').each(function() {
       console.log($(this).data(''));
        companies.push($(this).data('answer'))
    });
    let selectedCompany = companies.join(', ')
    let language = $('input[data-variable="consentLanguageOriginal"]').val()
    let updateConsent = language.replace('{{companies}}', selectedCompany)
    $('#text-block-id-55aa8e9b div h5 span').html(updateConsent)
    $('input[data-variable="consentLanguage2"]').val(updateConsent)
    $('#leadid_tcpa_disclosure').next().html(updateConsent)
}

const sendIpSlackAlert = (ipResponse) => {
    try {
        var requestUrl = window.location.origin + window.location.pathname + window.location.hash
        let request = null
        let consumerSourceIP = null
        let consumerSourceIPInput = $('section[name="start"] input[data-variable="consumerSourceIP"]')
        if (consumerSourceIPInput.length > 0) {
            consumerSourceIP = consumerSourceIPInput[0].value
        }
        if (ipResponse === null || ipResponse === undefined) {
            request = {
                "username": "HeyFlow IP Alert From Submit Lead",
                "text": `*URL:* ${requestUrl}\n*SessionStorage IP:* ${sessionStorage.consumerSourceIP}\n*consumerSourceIP:* ${consumerSourceIP}`
            }
        } else {
            request = {
                "username": "HeyFlow IP Alert",
                "text": `*URL:* ${requestUrl}\n*consumerSourceIP:* ${consumerSourceIP}\n*SessionStorage IP:* ${sessionStorage.consumerSourceIP}\n*IP Response:* \`\`\`${JSON.stringify(ipResponse, undefined, 2)}\`\`\``
            }
        }
        
        var url = "https://hooks.slack.com/services/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"
        console.log('[sendIpSlackAlert] url: ', url)
        console.log('[sendIpSlackAlert] request: ', request)
        var settings = {
          "url": "https://hooks.slack.com/services/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",
          "method": "POST",
          "timeout": 0,
          "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          "data": {
            "payload": JSON.stringify(request)
          }
        };
        /*$.ajax(settings).done(function (response) {
          console.log('[sendIpSlackAlert] slack_response: ', response)
        })*/
    } catch (error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
}

const sendSlackAlert = () => {
    try {
        if (eventDetailsObject === null) {
            console.log('[sendSlackAlert] eventDetailsObject is null')
            return
        }
        
        var requestUrl = window.location.origin + window.location.pathname + window.location.hash
        let screenName = eventDetailsObject.screenName
        let fieldsSimple = eventDetailsObject.fieldsSimple
        
        let request = {
            "username": "HeyFlow Alert",
            //"icon_emoji": ":alert:",
            "text": `*URL:* ${requestUrl}\n*ScreenName:* ${screenName}\n*SessionStorage IP:* ${sessionStorage.consumerSourceIP}\n*FieldsSimple:* \`\`\`${JSON.stringify(fieldsSimple, undefined, 2)}\`\`\``
        }
        
        var url = "https://hooks.slack.com/services/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"
        console.log('[sendSlackAlert] url: ', url)
        console.log('[sendSlackAlert] request: ', request)
        var settings = {
          "url": url,
          "method": "POST",
          "timeout": 0,
          "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          "data": {
            "payload": JSON.stringify(request)
          }
        };
        /*$.ajax(settings).done(function (response) {
          console.log('[sendSlackAlert] slack_response: ', response)
        })*/
    } catch (error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
}

const sendErrorSlackAlert = (error) => {
    
    var requestUrl = window.location.origin + window.location.pathname + window.location.hash
    let request = {
        "username": "HeyFlow Error",
        "text": `*URL:* ${requestUrl}\n*Error:* ${error}\n*Stacktrace:* \`\`\`${error.stack}\`\`\``
    }
    
    var url = "https://hooks.slack.com/services/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"
    console.log('[sendErrorSlackAlert] url: ', url)
    console.log('[sendErrorSlackAlert] request: ', request)
    var settings = {
      "url": url,
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "data": {
        "payload": JSON.stringify(request)
      }
    };
    /*$.ajax(settings).done(function (response) {
      console.log('[sendErrorSlackAlert] slack_response: ', response)
    })*/
}

const sendCaptchFailedAlert = (captchaResponse) => {
    try {
        if (eventDetailsObject === null) {
            console.log('[sendCaptchFailedAlert] eventDetailsObject is null')
            return
        }
        
        var requestUrl = window.location.origin + window.location.pathname + window.location.hash
        let fieldsSimple = eventDetailsObject.fieldsSimple
        
        let request = {
            "username": "Captcha Bot Alert",
            //"icon_emoji": ":alert:",
            "text": `*URL:* ${requestUrl}\n*Id:* ${captchaResponse.autoLeadCaptchaStatId}\n*Captcha Score:* ${captchaResponse.score}\n*Error:* ${captchaResponse.errors}\n*FieldsSimple:* \`\`\`${JSON.stringify(fieldsSimple, undefined, 2)}\`\`\``
        }
        
        var url = "https://hooks.slack.com/services/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"
        console.log('[sendCaptchFailedAlert] url: ', url)
        console.log('[sendCaptchFailedAlert] request: ', request)
        var settings = {
          "url": url,
          "method": "POST",
          "timeout": 0,
          "headers": {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          "data": {
            "payload": JSON.stringify(request)
          }
        };
        $.ajax(settings).done(function (response) {
          console.log('[sendCaptchFailedAlert] slack_response: ', response)
        })
    } catch (error) {
        console.log('ERROR: ', error.stack)
        sendErrorSlackAlert(error)
    }
}
